import React, { forwardRef } from 'react';

import type { OverlayContainerType } from 'components/containerProvider/containerProvoder.types';

import * as S from './OverlaySc';

interface OverlayProps {
  type: OverlayContainerType;
}

const OverlayRenderer: React.ForwardRefRenderFunction<HTMLDivElement, OverlayProps> = (
  { type },
  ref
) => <S.Container ref={ref} type={type} />;

export const Overlay = forwardRef(OverlayRenderer);
Overlay.displayName = 'Overlay';
