import tw, { css, styled } from 'twin.macro';

import { appStyles } from 'styles/appStyles';

export const Container = styled.div(() => [
  css`
    ${tw`px-12`};
  `
]);

export const TableWrapper = styled.div(() => [
  css`
    a:hover {
      ${tw`text-primary-600`};
    }

    & .ant-table .ant-table-row-selected > td {
      ${tw`bg-gray-100`};

      background: ${appStyles.colors['primary-50']} !important;
    }

    & tr td {
      ${tw`text-14 cursor-pointer`}
    }
  `
]);

export const Header = styled.section(() => [
  css`
    ${tw`flex justify-between pt-8 pb-12 sticky top-64 bg-gray-100`};
    z-index: 1000;

    & > .right {
      ${tw`flex items-center`};

      & .ant-btn {
        ${tw`ml-12 hover:bg-primary-600 hover:text-white`};

        &.active {
          ${tw`bg-primary-700 hover:bg-primary-600 text-white`};
        }
      }
    }
  `
]);

export const Thumbnail = styled.img(() => [
  css`
    ${tw`px-12 max-w-90`};
  `
]);

export const Grid = styled.div(() => [
  css`
    ${tw`grid grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 relative gap-24`};

    & > div {
      ${tw`w-full`};
    }
  `
]);

export const Paginator = styled.div(() => [
  css`
    ${tw`flex items-center`};

    & .ant-pagination-item {
      ${tw`border-none`};
    }

    & .ant-pagination-item-active {
      ${tw`bg-transparent`};
    }

    & .ant-pagination-prev,
    .ant-pagination-next {
      transform: translateY(-3px);
    }

    & .ant-select-selector {
      ${tw`bg-transparent`};
    }
  `
]);

export const SearchInput = styled.div(() => [
  css`
    a:hover {
      ${tw`text-primary-600`};
    }
  `
]);

interface GridItemProps {
  isActive: boolean;
}
export const GridItem = styled.div<GridItemProps>(({ isActive }) => [
  css`
    ${tw`ring-2 ring-transparent transition-all duration-300 relative`};

    & .info {
      ${tw` bg-primary-900 text-white text-13 px-4 py-2 opacity-90 transition-all`};
    }

    & .controls {
      ${tw`absolute top-5 left-5 opacity-0 z-10`}

      & .ant-switch {
        ${tw`bg-gray-700 hover:bg-gray-600`}

        & .ant-switch-handle:before {
          ${tw`bg-gray-500`}
        }

        &.ant-switch-checked {
          ${tw`bg-gray-700 hover:bg-gray-300`}

          & .ant-switch-handle:before {
            ${tw`bg-gray-500`}
          }
        }
      }
    }

    &:hover {
      ${tw`ring-2 ring-primary-800`};

      & .info {
        ${tw`opacity-100`};
      }

      & .controls {
        ${tw`opacity-100`};
      }
    }
  `,

  !isActive &&
    css`
      ${tw`opacity-80`}

      img {
        ${tw`opacity-40`}
      }
    `
]);

export const TableActions = styled.div(() => [
  css`
    ${tw`px-12 max-w-100`};

    & .ant-switch {
      ${tw`bg-gray-100 hover:bg-gray-200`}

      & .ant-switch-handle:before {
        ${tw`bg-gray-400`}
      }

      &.ant-switch-checked {
        ${tw`bg-gray-200 hover:bg-gray-300`}

        & .ant-switch-handle:before {
          ${tw`bg-gray-400`}
        }
      }
    }
  `
]);
