import React from 'react';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as S from './ImagePlaceholderSc';
import { ImagePlaceholderProps } from './types';

const ImagePlaceholder: React.FC<ImagePlaceholderProps> = ({ isGrid }) => (
  <S.Container isGrid={isGrid}>
    <div>
      <FontAwesomeIcon icon={regular('image-slash')} />
    </div>
  </S.Container>
);

export default ImagePlaceholder;
