import { useMemo } from 'react';

import { Job, useRunningJobs } from 'services/api/rest/jobs';

export const useRunningJob = (data: Job) => {
  const runningJobs = useRunningJobs();
  const runningJob = runningJobs.data?.find(job => job.id === data.id);

  const percent = useMemo(() => {
    if (!runningJob) return 0;

    return Math.round(
      ((runningJob.transformSuccessful + runningJob.transformFailed + runningJob.transformOther) /
        runningJob.transformTotal) *
        100
    );
  }, [runningJob]);

  const jobData = runningJob || data;

  return {
    jobData,
    percent,
    isRunning: runningJob !== undefined
  };
};
