/* eslint-disable no-param-reassign */
import type { AxiosError, AxiosRequestConfig } from 'axios';
import axios from 'axios';

import { useAuthStore } from 'services/store/auth';

// eslint-disable-next-line no-unused-vars
const handleResponseError = (error: AxiosError) => {
  /* if (error.response?.status === 401) {
    const basepath = getSlugs(window.location.pathname)[1];
    if (basepath !== 'home') {
      window.location.href = window.location.origin;
    }
  } */
};

const beforeRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  if (!config.headers) {
    config.headers = {};
  }

  const { token } = useAuthStore.getState();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  if (config.method === 'post' || config.method === 'put' || config.method === 'patch') {
    config.headers['Content-Type'] = 'application/json';

    if (config.data && typeof config.data === 'object') {
      config.data = JSON.stringify(config.data);
    }
  }

  return config;
};

export const apiClient = axios.create({
  baseURL: window._env_.BUSINESS_API_URL
  //   timeout: DEFAULT_TIMEOUT
});

apiClient.interceptors.response.use(
  res => res,
  error => handleResponseError(error)
);

apiClient.interceptors.request.use(config => beforeRequest(config));
