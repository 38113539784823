import tw, { styled, css } from 'twin.macro';

interface ContainerProps {
  isActive: boolean;
  isRunnig: boolean;
}

export const ListItem = styled.div<ContainerProps>(({ isActive, isRunnig, theme }) => [
  css`
    ${tw`text-left block h-72 overflow-hidden cursor-pointer`};
    width: calc(100% - 12px);

    & > div {
      ${tw`text-left block bg-gray-50 p-12 mx-6 my-4 rounded-6 border-gray-200 border grid grid-cols-4 gap-12 opacity-90`};

      & > div {
        p:nth-child(1) {
          ${tw`font-600 text-primary-700 mb-4`}
        }

        p:nth-child(2) {
          ${tw`font-400 text-14 text-gray-600`}
        }

        &.actions {
          ${tw`flex justify-end items-center h-full transition-all opacity-0`}

          & .ant-btn {
            ${tw`ml-8`}
          }

          & .ant-progress-circle-path {
            fill: ${theme.colors.success};
          }
        }
      }
    }
  `,

  isActive &&
    css`
      & > div {
        ${tw`border-primary-400 shadow-md bg-white opacity-100`};

        & > div.actions {
          ${tw`opacity-100`};
        }
      }
    `,

  isRunnig &&
    css`
      & > div {
        ${tw`bg-blue-50 border-blue-600`};

        & > div.actions {
          ${tw`opacity-100`};
        }
      }
    `
]);

export const Status = styled.p(() => [
  css`
    & .status {
      ${tw`font-600`}

      &.success {
        ${tw`text-success`};
      }

      &.error {
        ${tw`text-error`};
      }

      &.other {
        ${tw`text-blue-500`};
      }
    }
  `
]);

export const SingleItem = styled.div(() => [
  css`
    ${tw`bg-white rounded-12 border-gray-200 absolute top-12 left-12 right-12 bottom-12 overflow-x-hidden overflow-y-auto shadow-md`};

    & h3 {
      ${tw`text-22 text-white p-16 bg-primary-800 flex justify-between`};

      & span {
        ${tw`inline-block`}

        &:first-of-type {
          ${tw`font-700`};
        }
      }
    }

    & > div {
      ${tw`text-16 p-16 border-b border-gray-200`};

      & p {
        ${tw`text-16 py-2 flex items-start`};

        & span {
          ${tw`inline-block`};

          &:first-of-type {
            ${tw`font-600 text-primary-700 text-14 min-w-100`};
          }
        }
      }
    }

    & .actions {
      ${tw`flex`}

      & .ant-btn {
        ${tw`mr-12`}

        &.running {
          ${tw`bg-success-bg border-success text-success`}
        }

        &.delete {
          ${tw`bg-error-bg border-error text-error ml-auto`}
        }

        & svg {
          ${tw`mr-8`};
        }
      }
    }
  `
]);

export const Progress = styled.div(() => [
  css`
    & .status {
      ${tw`text-gray-500 text-13`};
    }
  `
]);

export const LastImport = styled.div(() => [
  css`
    & > p {
      ${tw`font-600 text-primary-700 text-14 min-w-100 mb-6`};
    }

    & > div {
      ${tw`flex`}

      & > div.left {
        ${tw`w-160 h-109`}
      }

      & > div.right {
        ${tw`ml-24`};

        & > div {
          ${tw`ml-12`}
        }
      }
    }
  `
]);
