import React, { useEffect, useMemo } from 'react';

import { base64toObject, objectToBase64 } from '@arpia/utils';
import { ApiSearchQueryParams, DATE_TIME_FORMAT, defaultVideosQuery, Video } from '@cheyes/shared';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, Modal, Pagination, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { findIndex } from 'lodash';
import prettyBytes from 'pretty-bytes';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';

import { useContainer } from 'components/containerProvider/useContainer';
import ImagePlaceholder from 'components/imagePlaceholder/ImagePlaceholder';
import ToogleActiveAction from 'components/toogleActiveAction/ToogleActiveAction';
import VideoPlayer from 'components/video';
import { useVideos } from 'services/api/rest/videos';
import { appStyles } from 'styles/appStyles';
import { getRoundedDuration } from 'utils';

import * as S from './VideosTableSc';

const DEFAULT_MODE = 'grid';

const VideosTable: React.FC = () => {
  const [queryString, setQueryString] = useQueryParam('q', StringParam);
  const [mode, setMode] = useQueryParam<'list' | 'grid'>('mode');
  const [id, setId] = useQueryParam('id', NumberParam);
  const [row, setRow] = useQueryParam('row', NumberParam);
  const { dialog } = useContainer();
  const query: ApiSearchQueryParams = useMemo(() => {
    if (!queryString) return defaultVideosQuery;

    return base64toObject(queryString) as ApiSearchQueryParams;
  }, [queryString]);

  const { data, isLoading, refetch } = useVideos(query);
  const currentVideo = data?.items.find(video => video.id === id);

  const currentIndex = useMemo(() => {
    if (!currentVideo || !data || !data?.items) return null;

    return findIndex(data.items, { id: currentVideo.id });
  }, [currentVideo, data]);

  const next = useMemo(() => {
    if (currentIndex === null || !data || currentIndex === data.items.length - 1) return null;

    return data.items[currentIndex + 1];
  }, [currentIndex, data]);

  const prev = useMemo(() => {
    if (!currentIndex || !data) return null;

    return data.items[currentIndex - 1];
  }, [currentIndex, data]);

  const columns: ColumnsType<Video> = useMemo(
    () => [
      {
        title: <FontAwesomeIcon icon={regular('image')} />,
        dataIndex: 'img_thumb',
        render: (thumbUrl: string, record) =>
          thumbUrl ? (
            <S.Thumbnail
              src={thumbUrl}
              alt={record.name}
              style={{ opacity: record.active ? 1 : 0.3 }}
            />
          ) : (
            <ImagePlaceholder />
          ),
        width: 120
      },
      {
        title: <FontAwesomeIcon icon={regular('eye')} />,
        dataIndex: 'active',
        sorter: true,
        render: (acitve: boolean) => (
          <FontAwesomeIcon
            icon={acitve ? solid('check') : solid('close')}
            color={acitve ? appStyles.colors.success : appStyles.colors.error}
          />
        )
      },
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: true
      },
      {
        title: 'Dbox modified',
        dataIndex: 'dbox_serverModified',
        render: (date: string) => dayjs(date).format(DATE_TIME_FORMAT),
        sorter: true
      },
      {
        title: 'Size',
        dataIndex: 'fileSize',
        sorter: true,
        render: (size: number) => (size ? prettyBytes(size) : '-')
      },
      {
        title: 'Duration',
        dataIndex: 'fileDuration',
        sorter: true,
        render: (duration: number) => (duration ? getRoundedDuration(duration) : '-')
      },
      {
        title: 'Dropbox',
        dataIndex: 'dbox_pathDisplay',
        sorter: true,
        render: (value: string) => {
          const url = `https://www.dropbox.com/home/Chameleon%20Eyes/${value}`;

          return (
            <a href={url} target="_blank" rel="noreferrer" title={url}>
              ...{value.replace('/ChameleonEyes/recJulia02/', '')}
            </a>
          );
        }
      },
      {
        title: 'Status',
        dataIndex: 'transformStatus',
        sorter: true
      },
      {
        title: 'Updated at',
        dataIndex: 'updatedAt',
        sorter: true,
        render: (date: string) => dayjs(date).format(DATE_TIME_FORMAT)
      },
      {
        title: 'Created at',
        dataIndex: 'createdAt',
        sorter: true
      },
      {
        title: <FontAwesomeIcon icon={solid('cog')} />,
        dataIndex: '',
        render: (item: undefined, record) => (
          <S.TableActions className="controls">
            <ToogleActiveAction data={record} />
          </S.TableActions>
        )
      }
    ],
    []
  );

  useEffect(() => {
    refetch();
  }, [query, refetch]);

  useEffect(() => {
    if (!queryString) {
      setQueryString(objectToBase64(defaultVideosQuery));
    }
  }, [queryString, setQueryString]);

  useEffect(() => {
    if (!mode) {
      setMode(DEFAULT_MODE);
    }
  }, [setMode, mode]);

  useEffect(() => {
    if (!id) return;

    setRow(id);
  }, [id, setRow]);

  return (
    <>
      <S.Container>
        <S.Header>
          <S.SearchInput>
            <Input.Search size="small" allowClear />
          </S.SearchInput>
          <div className="right">
            <S.Paginator>
              <Pagination
                total={data?.meta.totalItems}
                current={query.pagination?.page}
                pageSize={query.pagination?.limit}
                size="small"
                responsive
                onChange={(page, pageSize) => {
                  const newQuery: ApiSearchQueryParams = {
                    ...query,
                    pagination: {
                      page: page || 1,
                      limit: pageSize || 10
                    }
                  };

                  window.scrollTo({ top: 0 });

                  setQueryString(objectToBase64(newQuery));
                }}
              />
            </S.Paginator>
            <Button
              className={clsx([mode === 'list' && 'active'])}
              size="small"
              icon={<FontAwesomeIcon icon={solid('list')} type="ghost" />}
              onClick={() => setMode('list')}
            />
            <Button
              className={clsx([mode === 'grid' && 'active'])}
              size="small"
              icon={<FontAwesomeIcon icon={solid('grid')} type="ghost" />}
              onClick={() => setMode('grid')}
            />
          </div>
        </S.Header>

        {mode === 'list' && (
          <S.TableWrapper>
            <Table
              scroll={{ scrollToFirstRowOnChange: true }}
              size="small"
              loading={isLoading}
              dataSource={data?.items}
              columns={columns}
              rowKey="id"
              rowSelection={{
                type: 'radio',
                selectedRowKeys: row ? [row] : [],
                onSelect: record => setId(record.id)
              }}
              onRow={record => ({
                onClick: e => {
                  const tg = e.target as HTMLElement;
                  if (tg.classList.contains('controls') || tg.closest('.controls') !== null) {
                    return;
                  }
                  setId(record.id);
                }
              })}
              pagination={
                false
                /* {
                total: data?.meta.totalItems,
                current: query.pagination?.page,
                pageSize: query.pagination?.limit,
                size: 'small',
                responsive: true
              } */
              }
              tableLayout="auto"
              onChange={(pagination, filter, _sorter) => {
                const sorter = _sorter as SorterResult<Video>;

                const { sort } = query;
                if (sorter.column?.dataIndex && sorter.order) {
                  sort.field = sorter.column?.dataIndex as string;
                  sort.direction = sorter.order === 'ascend' ? 'ASC' : 'DESC';
                }

                const newQuery: ApiSearchQueryParams = {
                  ...query,
                  sort,
                  pagination: {
                    page: pagination.current || 1,
                    limit: pagination.pageSize || 10
                  }
                };

                window.scrollTo({ top: 0 });

                setQueryString(objectToBase64(newQuery));
              }}
            />
          </S.TableWrapper>
        )}

        {mode === 'grid' && (
          <S.Grid>
            {data?.items.map(item => (
              <S.GridItem
                isActive={item.active}
                key={item.id}
                className="group"
                role="button"
                onClick={e => {
                  const tg = e.target as HTMLElement;
                  if (tg.classList.contains('controls') || tg.closest('.controls') !== null) {
                    return;
                  }

                  setId(item.id);
                }}
              >
                {item.img ? <img src={item.img} alt={item.name} /> : <ImagePlaceholder isGrid />}
                <div className="info">
                  <p>{item.name}</p>
                  <p>{dayjs(item.dbox_clientModified).format(DATE_TIME_FORMAT)}</p>
                </div>
                <div className="controls">
                  <ToogleActiveAction data={item} />
                </div>
              </S.GridItem>
            ))}
          </S.Grid>
        )}
      </S.Container>

      <Modal
        centered
        width={722}
        open={currentVideo !== undefined}
        cancelText="close"
        onCancel={() => setId(undefined)}
        getContainer={() => dialog}
        rootClassName="video-dialog"
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
      >
        <VideoPlayer
          data={currentVideo}
          controls
          next={next}
          prev={prev}
          autoplay
          onNext={() => setId(next?.id)}
          onPrev={() => setId(prev?.id)}
        />
      </Modal>
    </>
  );
};

export default VideosTable;
