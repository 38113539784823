import { keys } from 'lodash';

import type { QueryParameterName, SearchParameterName, UrlParameters } from 'types';

export const getRoute = (_route: string, params?: UrlParameters): string => {
  let route = _route;

  if (!params) {
    return route;
  }

  if (params.query) {
    const queryKeys = keys(params?.query);

    (queryKeys as QueryParameterName[]).forEach(qk => {
      const queryVal = params.query?.[qk];
      if (queryVal) {
        const repl = new RegExp(`:${qk}`);
        route = route.replace(repl, encodeURIComponent(queryVal));
      }
    });
  }

  if (route !== '/') {
    route += '/';
  }

  if (params.search) {
    const values: string[] = [];

    const searchKeys = keys(params.search);

    (searchKeys as SearchParameterName[]).forEach(sk => {
      if (params?.search?.[sk]) {
        values.push(`${sk}=${params.search[sk]}`);
      }
    });

    route += `?${values.join('&')}`;
  }

  return route;
};
