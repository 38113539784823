import React, { useEffect, useMemo, useRef } from 'react';

import { base64toObject, objectToBase64 } from '@arpia/utils';
import { ApiSearchQueryParams, VIDEO_START_DATE } from '@cheyes/shared';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { Button, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';

import JobDetail from 'components/job/JobDetail';
import JobItem from 'components/job/JobItem';
import { defualtJobsQuery, useJobs, useRunningJobs } from 'services/api/rest/jobs';
import { useImportVideosByDate } from 'services/api/rest/videos';

import * as S from './ImportsSc';

const Imports: React.FC = () => {
  // const reactListRef = useRef(null);
  const [queryString, setQueryString] = useQueryParam('q', StringParam);
  const [date, setDate] = useQueryParam('date', StringParam);
  const [jobId, setJobId] = useQueryParam('id', NumberParam);

  const importDate = useMemo(() => {
    if (!date) return dayjs();

    return dayjs(date, 'YYYY-MM-DD');
  }, [date]);

  const { current: today } = useRef(dayjs());
  const queryClient = useQueryClient();

  const query: ApiSearchQueryParams | null = useMemo(() => {
    if (!queryString) return null;

    return base64toObject(queryString) as ApiSearchQueryParams;
  }, [queryString]);

  const jobsQuery = useJobs(query || defualtJobsQuery);
  const { current: jobsQueryRef } = useRef(jobsQuery);

  const hasRunningJobs = useMemo(() => {
    if (!jobsQuery.data) return false;

    return jobsQuery.data.items.filter(job => job.status !== 'TERMINATED').length > 0;
  }, [jobsQuery.data]);

  const runningJobs = useRunningJobs({
    refetchInterval: 1000,
    enabled: hasRunningJobs,
    onSuccess: res => {
      if (res.length === 0) {
        jobsQuery.refetch();
      }
    }
  });
  const { current: runningJobsRef } = useRef(runningJobs);

  const currentJob = useMemo(
    () => jobsQuery.data?.items.find(job => job.id === jobId),
    [jobId, jobsQuery.data?.items]
  );

  const importMutation = useImportVideosByDate({
    onSuccess: job => {
      setJobId(job.id);
      queryClient.refetchQueries(['jobs']);
    }
  });

  const handleImport = () => {
    if (!importDate) return;

    importMutation.mutate(importDate);
  };

  useEffect(() => {
    if (!hasRunningJobs) {
      runningJobsRef.remove();

      jobsQueryRef.refetch();

      return;
    }

    runningJobsRef.refetch();
  }, [hasRunningJobs, runningJobsRef, jobsQueryRef]);

  useEffect(() => {
    if (!jobsQuery.data || jobId) return;

    setJobId(jobsQuery.data.items[0]?.id);
  }, [jobsQuery.data, setJobId, jobId]);

  useEffect(() => {
    if (!query) return;

    jobsQueryRef.refetch();
  }, [query, jobsQueryRef]);

  useEffect(() => {
    if (!date) {
      setDate(today.format('YYYY-MM-DD'));
    }
  }, [date, setDate, today]);

  useEffect(() => {
    if (!queryString) {
      setQueryString(objectToBase64(defualtJobsQuery));
    }
  }, [queryString, setQueryString]);

  return (
    <S.Container>
      <S.Header>
        <div className="left">
          <DatePicker
            onChange={d => setDate((d || dayjs()).format('YYYY-MM-DD'))}
            value={importDate}
            format="DD.MM.YYYY"
            disabledDate={d => d >= today || d < dayjs(VIDEO_START_DATE)}
            disabled={runningJobs.data && runningJobs.data?.length > 0}
          />
          <Button
            onClick={handleImport}
            type="primary"
            icon={<FontAwesomeIcon icon={solid('file-import')} />}
            disabled={runningJobs.data && runningJobs.data?.length > 0}
          >
            Import
          </Button>
        </div>
        <div className="right">
          <p>
            <span>Total jobs:</span> <span>{jobsQuery.data?.meta.totalItems}</span>
          </p>
          <p>
            <span>Running jobs:</span>{' '}
            <span>{jobsQuery.data?.items.filter(item => item.status !== 'TERMINATED').length}</span>
          </p>
          <Button
            icon={<FontAwesomeIcon icon={solid('sync')} />}
            size="small"
            onClick={() => jobsQuery.refetch()}
          />
        </div>
      </S.Header>

      <S.Main>
        <div className="left">
          <S.List>
            {jobsQuery.data?.items.map((item, index) => (
              <JobItem key={item.id} data={item} index={index} />
            ))}
            {/* {jobsQuery.data && (
              <ReactList
                //  ref={reactListRef}
                length={jobsQuery.data?.items.length || 0}
                type="uniform"
                // itemSizeGetter={() => 72}
                // eslint-disable-next-line react/no-unstable-nested-components
                itemRenderer={(index: number, key: any) => {
                  console.log(index, key);

                  return (
                    <div key={key}>
                      <JobItem key={key} data={jobsQuery.data?.items[index]} index={index} />
                    </div>
                  );
                }}
              />
            )} */}
          </S.List>
        </div>
        <div className="right">{currentJob && <JobDetail job={currentJob} />}</div>
      </S.Main>
    </S.Container>
  );
};

export default Imports;
