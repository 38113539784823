import type { ApiSearchQueryParams, Video } from '@cheyes/shared';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';

import { UseMutation, UseQueryWithParams } from 'services/api/types';

import {
  api_activateVideo,
  api_deactivateVideo,
  api_getVideosById,
  api_getVideoss,
  api_importVideosByDate,
  api_rateVideoById
} from './videos.queries';
import { RateVideoParams, VideoListResponse } from './videos.types';

/**
 * QUERIES
 */
export const useVideos: UseQueryWithParams<ApiSearchQueryParams, VideoListResponse> = (
  queryParams,
  options
) => useQuery(['videos'], ({ signal }) => api_getVideoss(queryParams, signal), options as any);

export const useVideosById: UseQueryWithParams<number, Video> = (id, options) =>
  useQuery(['videos', 'byId', id], ({ signal }) => api_getVideosById(id, signal), options as any);

/**
 * MUTATIONS
 */
export const useImportVideosByDate: UseMutation<Dayjs, Video> = options =>
  useMutation(params => api_importVideosByDate(params), options);

export const useActivateVideoById: UseMutation<number, Video> = options =>
  useMutation(id => api_activateVideo(id), options);

export const useDeactivateVideoById: UseMutation<number, Video> = options =>
  useMutation(id => api_deactivateVideo(id), options);

export const useRateVideoById: UseMutation<RateVideoParams, Video> = options =>
  useMutation(params => api_rateVideoById(params), options);
