import React, { useEffect, useRef } from 'react';

import { DATE_FORMAT, DATE_TIME_FORMAT } from '@cheyes/shared';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Progress } from 'antd';
import dayjs from 'dayjs';
import prettyBytes from 'pretty-bytes';
import { StringParam, useQueryParam } from 'use-query-params';

import { useJobId, useRunningJob } from 'hooks';
import { Job as JobType } from 'services/api/rest/jobs';
import { useImportVideosByDate, useVideosById } from 'services/api/rest/videos';
import { getRoundedDuration } from 'utils';

import AbortJobAction from './AbortJobAction';
import * as S from './JobSc';

interface JobProps {
  job: JobType;
}

const JobDetail: React.FC<JobProps> = ({ job }) => {
  const { jobData, isRunning, percent } = useRunningJob(job);
  const { setJobId } = useJobId();
  const [, setDate] = useQueryParam('date', StringParam);
  const queryClient = useQueryClient();

  const videoQuery = useVideosById(jobData.lastSuccessfullVideoId || 0, { enabled: false });
  const { current: videoQueryRef } = useRef(videoQuery);

  const importMutation = useImportVideosByDate({
    onSuccess: newJob => {
      setJobId(newJob.id);
      queryClient.refetchQueries(['jobs']);
    }
  });

  const handleRunAgain = () => {
    setDate(job.dateToFetch);
    importMutation.mutate(dayjs(job.dateToFetch));
  };

  useEffect(() => {
    if (!jobData.lastSuccessfullVideoId) return;

    videoQueryRef.refetch();
  }, [jobData.lastSuccessfullVideoId, videoQueryRef]);

  return (
    <S.SingleItem>
      <h3>
        <span>
          Job {jobData.id} - {dayjs(jobData.dateToFetch).format(DATE_FORMAT)}
        </span>
        <span>{dayjs(jobData.createdAt).local().format(DATE_TIME_FORMAT)}</span>
      </h3>
      <div>
        <p>
          <span>Import date:</span>
          <span>{dayjs(jobData.dateToFetch).format(DATE_FORMAT)}</span>
        </p>
      </div>
      <div>
        <p>
          <span>Status:</span>
          <span>{jobData.status}</span>
        </p>
        {isRunning && (
          <S.Progress>
            <Progress percent={percent} />
            <div className="status">{jobData.currentVideoName}</div>
          </S.Progress>
        )}
      </div>
      <div>
        <p>
          <span>Total:</span>
          <span>{jobData.transformTotal}</span>
        </p>
        <S.Status>
          <span>Success:</span>
          <span className="status success">{jobData.transformSuccessful}</span>
        </S.Status>
        <S.Status>
          <span>Failed:</span>
          <span className="status error">{jobData.transformFailed}</span>
        </S.Status>
        <S.Status>
          <span>Other:</span>
          <span className="status other">{jobData.transformOther}</span>
        </S.Status>
      </div>
      <div>
        <p>
          <span>Created:</span>
          <span>{dayjs(jobData.createdAt).local().format(DATE_TIME_FORMAT)}</span>
        </p>
        <p>
          <span>Updated:</span>
          <span>{dayjs(jobData.updatedAt).local().format(DATE_TIME_FORMAT)}</span>
        </p>
      </div>

      <div className="actions">
        <AbortJobAction job={job} mode="single" />

        <Button
          className="redo"
          icon={<FontAwesomeIcon icon={solid('sync')} />}
          onClick={handleRunAgain}
        >
          Run again
        </Button>

        <Button className="delete" icon={<FontAwesomeIcon icon={solid('trash')} />}>
          Delete
        </Button>
      </div>

      {videoQuery.data && (
        <S.LastImport>
          <p>Last import</p>
          <div>
            <div className="left">
              <img
                width={160}
                height={109}
                src={videoQuery.data?.img_thumb}
                alt={videoQuery.data.name}
              />
            </div>
            <div className="right">
              <p>{videoQuery.data.name}.mp4</p>
              <p>
                {prettyBytes(videoQuery.data.fileSize)},{' '}
                {getRoundedDuration(videoQuery.data.fileDuration)}s
              </p>
            </div>
          </div>
        </S.LastImport>
      )}
    </S.SingleItem>
  );
};
export default JobDetail;
