import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import type { AuthState } from './authStore.types';

const devToolsName = 'LANGUAGE_STORE';

export const useAuthStore = create<
  AuthState,
  [['zustand/devtools', never], ['zustand/immer', never]]
>(
  devtools(
    immer(set => ({
      token: undefined,

      setToken: token => {
        set(draft => {
          draft.token = token;
        });
      }
    })),
    { name: devToolsName }
  )
);

export type UseLanguageStore = ReturnType<typeof useAuthStore>;
