import { useMemo } from 'react';

import { languageConfig } from 'services/i18n/language.config';
import { Locale } from 'types';

export const useDefaultLocale = () => {
  const lang = useMemo(() => {
    let defaultLang = languageConfig.defaultLocale;

    const browserLang = navigator.language.split('-')[0]?.toLowerCase();
    if (browserLang && languageConfig.locales.includes(browserLang as Locale)) {
      defaultLang = browserLang as Locale;
    }

    /*   if (myself.data?.configuration?.language) {
      defaultLang = myself.data?.configuration.language;
    } */

    return defaultLang;
  }, []);

  return lang;
};
