import React from 'react';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';

import routes from 'services/router/routes';
import { getRoute, getSlugs } from 'utils';

import * as S from './MainNavigationSc';

const MainNavigation: React.FC = () => {
  const { pathname } = useLocation();
  const base = getSlugs(pathname)?.[1];
  const language = getSlugs(pathname)?.[0];

  return (
    <S.Container>
      <ul>
        <li>
          <Link
            className={clsx([base === routes.videos._segment && 'active'])}
            to={getRoute(routes.videos._path, { query: { language } })}
          >
            <FontAwesomeIcon icon={regular('video')} />
            <Trans>Videos</Trans>
          </Link>
        </li>
        <li>
          <Link
            className={clsx([base === routes.import._segment && 'active'])}
            to={getRoute(routes.import._path, { query: { language } })}
          >
            <FontAwesomeIcon icon={regular('file-import')} />
            <Trans>Import</Trans>
          </Link>
        </li>
      </ul>
    </S.Container>
  );
};

export default MainNavigation;
