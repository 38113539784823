import type { ApiSearchQueryParams, Video } from '@cheyes/shared';
import { Dayjs } from 'dayjs';
import { stringify } from 'qs';

import { apiClient } from 'services/api/clients';

import { RateVideoParams, VideoListResponse } from './videos.types';

const BASE_PATH = 'videos';

/**
 * GET /api/videos
 */
export const api_getVideoss = async (
  queryParams: ApiSearchQueryParams,
  signal?: AbortSignal
): Promise<VideoListResponse> => {
  const query = stringify(queryParams, { encodeValuesOnly: true });
  const { data } = await apiClient.get(`/${BASE_PATH}`, { signal, params: { query } });

  return data;
};

/**
 * GET /api/videos/{id}
 */
export const api_getVideosById = async (id: number, signal?: AbortSignal): Promise<Video> => {
  const { data } = await apiClient.get(`/${BASE_PATH}/${id}`, { signal });

  return data;
};

/**
 * POST /api/videos/import/bydate/{date}
 */
export const api_importVideosByDate = async (date: Dayjs): Promise<Video> => {
  const { data } = await apiClient.post(`/${BASE_PATH}/import/bydate/${date.format('YYYY-MM-DD')}`);

  return data;
};

/**
 * PATCH /api/videos/rating/{id}/{rating}
 */
export const api_rateVideoById = async (params: RateVideoParams): Promise<Video> => {
  const { data } = await apiClient.patch(`/${BASE_PATH}/rating/${params.id}/${params.rating}`);

  return data;
};

/**
 * PATCH /api/videos/activate/{id}
 */
export const api_activateVideo = async (id: number): Promise<Video> => {
  const { data } = await apiClient.patch(`/${BASE_PATH}/activate/${id}`);

  return data;
};

/**
 * PATCH /api/videos/deactivate/{id}
 */
export const api_deactivateVideo = async (id: number): Promise<Video> => {
  const { data } = await apiClient.patch(`/${BASE_PATH}/deactivate/${id}`);

  return data;
};
