import { Button } from 'antd';
import tw, { styled, css } from 'twin.macro';

interface Props {
  $isIconOnly?: boolean;
}

export const Container = styled(Button)<Props>(({ $isIconOnly }) => [
  css`
    ${tw`font-600 text-15 text-white px-14 font-sans min-w-60`};
    background: rgba(255, 255, 255, 0.1);

    & svg {
      ${tw`mr-8`};
    }

    &:hover {
      background: rgba(255, 255, 255, 0.16);
    }
  `,

  $isIconOnly &&
    css`
      & svg {
        ${tw`mr-0`};
      }
    `
]);
