import { Link } from 'react-router-dom';
import tw, { css, styled } from 'twin.macro';

export const Container = styled.div(({ theme }) => [
  css`
    ${tw`bg-primary-1000 flex text-white px-16 justify-between items-center`};
    height: ${theme.headerHeight}px;
  `
]);

export const Logo = styled(Link)(() => [
  css`
    ${tw`inline-block relative h-full flex items-center justify-center opacity-80`};

    & h2 {
      ${tw`text-22 font-600 leading-24 text-blue-50`};
    }

    &:hover,
    &.active {
      ${tw`opacity-100`};

      & h2 {
        ${tw`text-white`};
      }
    }
  `
]);

export const Left = styled.div(() => [
  css`
    ${tw`flex items-start h-full flex-grow`};
  `
]);

export const Right = styled.div(() => [
  css`
    ${tw`ml-40 flex items-start justify-end`};
  `
]);
