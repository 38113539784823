/**
 * Do not edit this file. its generically produced form js modules
 */
import type { LanguageConfig } from 'types';

export const languageConfig: LanguageConfig = {
  locales: ['en'],
  defaultLocale: 'en',
  localeDetection: false
};
