import { ApiSearchQueryParams } from '@cheyes/shared';

export const defualtJobsQuery: ApiSearchQueryParams = {
  pagination: {
    page: 1,
    limit: 1000
  },
  filters: [],
  sort: {
    field: 'createdAt',
    direction: 'DESC'
  }
};
