import { VIDEO_RATIO } from '@cheyes/shared';
import tw, { css, styled } from 'twin.macro';

import { ImagePlaceholderProps } from './types';

export const Container = styled.div<ImagePlaceholderProps>(({ isGrid }) => [
  css`
    ${tw`bg-gray-100  w-76 h-52 w-full relative`};
    padding-top: ${VIDEO_RATIO * 100}%;

    & > div {
      ${tw`absolute top-0 left-0 right-0 bottom-0 w-full flex items-center justify-center`};

      & svg {
        ${tw`text-gray-500`};
      }
    }
  `,

  isGrid &&
    css`
      ${tw`bg-gray-200 flex items-center justify-center w-full`};

      & > div svg {
        ${tw`text-20`};
      }
    `
]);
