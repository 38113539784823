import React from 'react';

import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Button } from 'antd';
import type { FallbackProps } from 'react-error-boundary';
import { useNavigate } from 'react-router';

import * as S from './PageWrapperSc';

type PageContentErrorFallbackProps = FallbackProps & {
  children?: React.ReactNode;
};

const PageContentErrorFallback: React.FC<PageContentErrorFallbackProps> = ({
  error,
  resetErrorBoundary
}) => {
  const navigate = useNavigate();

  return (
    <S.ErrorInfo role="alert">
      <div className="info">
        <h2>
          <FontAwesomeIcon icon={solid('bug')} />
          <Trans>Something went wrong:</Trans>
        </h2>
        <p>
          <Trans>Error</Trans>
        </p>
        <pre>{error.message}</pre>
      </div>

      <div className="buttons">
        <Button
          size="small"
          type="ghost"
          onClick={resetErrorBoundary}
          icon={<FontAwesomeIcon icon={regular('undo')} />}
        >
          <Trans>Try again</Trans>
        </Button>
        <Button
          size="small"
          type="ghost"
          onClick={() => navigate(-1)}
          icon={<FontAwesomeIcon icon={regular('undo')} />}
        >
          <Trans>Back</Trans>
        </Button>
      </div>
    </S.ErrorInfo>
  );
};

export default PageContentErrorFallback;
