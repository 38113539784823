import { getRem } from '@arpia/utils';
import tw, { css, styled } from 'twin.macro';

interface LoaderBoxProps {
  height?: number | string;
  width?: number | string;
  color?: string;
  flex?: boolean;
  size?: number;
}

export const StyledLoader = styled.div<LoaderBoxProps>(({ height, width, color, size }) => [
  css`
    & .ant-spin-dot {
      ${tw`text-gray-300`};
    }

    &.flex {
      ${tw`flex items-center justify-center text-gray-500`};
    }
  `,

  size !== undefined &&
    css`
      & .ant-spin-dot {
        font-size: ${getRem(size)};
      }
    `,

  height !== undefined &&
    typeof height === 'string' &&
    css`
      height: ${height};
    `,

  height !== undefined &&
    typeof height === 'number' &&
    css`
      height: ${getRem(height)};
    `,

  width !== undefined &&
    typeof width === 'string' &&
    css`
      width: ${width};
    `,

  width !== undefined &&
    typeof width === 'number' &&
    css`
      width: ${getRem(width)};
    `,

  color &&
    css`
      & .ant-spin-dot {
        color: ${color};
      }
    `
]);
