import { NumberParam, useQueryParam } from 'use-query-params';

export const useJobId = () => {
  const [jobId, setJobId] = useQueryParam('id', NumberParam);

  return {
    jobId,
    setJobId
  };
};
