import React, { forwardRef, useEffect, useRef } from 'react';

import { DATE_TIME_FORMAT, Video, VIDEO_HEIGHT, VIDEO_WIDTH } from '@cheyes/shared';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faDropbox } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import dayjs from 'dayjs';
import prettyBytes from 'pretty-bytes';
import ReactPlayer from 'react-player';

import ToogleActiveAction from 'components/toogleActiveAction/ToogleActiveAction';
import { getRoundedDuration } from 'utils';

import * as S from './VideoPlayerSc';

interface Props {
  controls?: boolean;
  autoplay?: boolean;
  data?: Video;
  onEnded?: () => void;
  prev?: Video | null;
  next?: Video | null;
  onNext?: () => void;
  onPrev?: () => void;
}

const VideoPlayerRenderer: React.ForwardRefRenderFunction<HTMLVideoElement, Props> = (
  { data, prev, next, autoplay, onNext, onPrev },
  // eslint-disable-next-line no-unused-vars
  ref
) => {
  const video = useRef<ReactPlayer>(null);

  useEffect(() => {
    if (!video.current) return;

    const player = video.current.getInternalPlayer() as HTMLVideoElement;
    if (player && autoplay) {
      player.play();
    }
  }, [data, autoplay]);

  if (!data) {
    return null;
  }

  return (
    <S.Container className="video-player">
      <ReactPlayer
        ref={video}
        url={data.filePath}
        controls
        muted
        height={VIDEO_HEIGHT}
        width={VIDEO_WIDTH}
      />

      <S.Controls>
        <ToogleActiveAction data={data} />
        <div className="controls">
          {onPrev && (
            <Button
              shape="circle"
              disabled={!prev}
              icon={<FontAwesomeIcon icon={solid('fast-backward')} />}
              onClick={onPrev}
            />
          )}
          {onNext && (
            <Button
              disabled={!next}
              size="small"
              shape="circle"
              icon={<FontAwesomeIcon icon={solid('fast-forward')} />}
              onClick={onNext}
            />
          )}
        </div>
      </S.Controls>

      <S.Info>
        <div>
          <a href={data.filePath} target="_blank" rel="noreferrer">
            <p>
              <FontAwesomeIcon icon={regular('download')} />
              {data.name}
            </p>
          </a>
          <a
            href={`https://www.dropbox.com/home/Chameleon%20Eyes/${data.dbox_pathDisplay}`}
            target="_blank"
            rel="noreferrer"
          >
            <p>
              <FontAwesomeIcon icon={faDropbox} />
              {data.dbox_pathDisplay.replace('/ChameleonEyes/recJulia02/', '')}
            </p>
          </a>
        </div>

        <div>
          <p>{dayjs(data.dbox_clientModified).format(DATE_TIME_FORMAT)}</p>
          <p>
            {prettyBytes(data.fileSize)} - {getRoundedDuration(data.fileDuration)}s
          </p>
        </div>
      </S.Info>
    </S.Container>
  );
};

const VideoPlayer = forwardRef(VideoPlayerRenderer);
export default VideoPlayer;
