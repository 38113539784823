import type { PropsWithChildren } from 'react';
import React, { createContext, useEffect, useRef, useState } from 'react';

import { Overlay } from 'components/overlay';

import { getContainer } from './getContainer';

export interface ContainerContextState {
  dialog: HTMLElement;
  notificiation: HTMLElement;
  content: HTMLElement;
  docs: HTMLElement;
}

export const ContainerContext = createContext({} as ContainerContextState);

export interface ContainerProviderProps extends PropsWithChildren {}

const ContainerProvider: React.FC<ContainerProviderProps> = ({ children }) => {
  const dialogContainer = useRef(null);
  const notificationsContainer = useRef(null);
  const contentContainer = useRef(null);
  const docsContainer = useRef(null);

  const [state, setState] = useState<ContainerContextState>({
    dialog: getContainer(dialogContainer),
    notificiation: getContainer(notificationsContainer),
    content: getContainer(contentContainer),
    docs: getContainer(docsContainer)
  });

  useEffect(() => {
    setState({
      dialog: getContainer(dialogContainer),
      notificiation: getContainer(notificationsContainer),
      content: getContainer(contentContainer),
      docs: getContainer(docsContainer)
    });
  }, []);

  return (
    <ContainerContext.Provider value={state}>
      {children}
      <Overlay ref={contentContainer} type="content" />
      <Overlay ref={dialogContainer} type="dialog" />
      <Overlay ref={docsContainer} type="docs" />
      <Overlay ref={notificationsContainer} type="notification" />
    </ContainerContext.Provider>
  );
};

export default ContainerProvider;
