import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { Locale } from 'types';

import type { LanguageState } from './languageStore.types';

const devToolsName = 'LANGUAGE_STORE';

export const useLanguageStore = create<
  LanguageState,
  [['zustand/devtools', never], ['zustand/immer', never]]
>(
  devtools(
    immer(set => ({
      language: 'de',

      setLanguage: (language: Locale) => {
        set(draft => {
          draft.language = language;
        });
      }
    })),
    { name: devToolsName }
  )
);

export type UseLanguageStore = ReturnType<typeof useLanguageStore>;
