import React, { useMemo, useState } from 'react';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'antd';

import { Job as JobType, useAbortRunningJob } from 'services/api/rest/jobs';
import { appStyles } from 'styles/appStyles';

interface Props {
  job: JobType;
  mode: 'list' | 'single';
}

const AbortJobAction: React.FC<Props> = ({ job, mode }) => {
  const [isHovered, setHovered] = useState(false);
  const icon = useMemo(
    () => (
      <FontAwesomeIcon
        color={isHovered ? appStyles.colors.error : appStyles.colors.success}
        icon={isHovered ? solid('cancel') : solid('cog')}
        spin={!isHovered}
      />
    ),
    [isHovered]
  );

  const queryClient = useQueryClient();

  const abortMuation = useAbortRunningJob({
    onSuccess: res => {
      // eslint-disable-next-line no-console
      console.log(res);
      queryClient.refetchQueries(['jobs']);

      // eslint-disable-next-line no-console
    }
  });

  const handleAbortJob = () => {
    abortMuation.mutate(job.id);
  };

  if (job.status === 'TERMINATED') {
    return null;
  }

  return (
    <Button
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      disabled={abortMuation.isLoading}
      onClick={handleAbortJob}
      className="running"
      icon={icon}
      loading={abortMuation.isLoading}
    >
      {mode === 'single' && 'Abort Running job'}
    </Button>
  );
};
export default AbortJobAction;
