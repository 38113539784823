import { useMemo } from 'react';

import { useLocation } from 'react-router';

import { Locale } from 'types';
import { getSlugs } from 'utils';

export const useLanguageFromPath = (): Locale => {
  const { pathname } = useLocation();
  const language = useMemo(() => getSlugs(pathname)[0], [pathname]);

  return language as Locale;
};
