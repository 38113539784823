import React from 'react';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'antd';

import { Job as JobType, useDeleteJobById } from 'services/api/rest/jobs';

interface Props {
  job: JobType;
  mode: 'list' | 'single';
}

const DeleteJobAction: React.FC<Props> = ({ job, mode }) => {
  const queryClient = useQueryClient();

  const deleteMutation = useDeleteJobById({
    onSuccess: res => {
      // eslint-disable-next-line no-console
      console.log(res);
      queryClient.refetchQueries(['jobs']);

      // eslint-disable-next-line no-console
    }
  });

  const handleDeleteJob = () => {
    deleteMutation.mutate(job.id);
  };

  return (
    <Button
      disabled={deleteMutation.isLoading}
      onClick={handleDeleteJob}
      className="delete"
      icon={<FontAwesomeIcon icon={solid('trash')} />}
      loading={deleteMutation.isLoading}
      size={mode === 'list' ? 'small' : 'middle'}
    >
      {mode === 'single' && 'Delete'}
    </Button>
  );
};

export default DeleteJobAction;
