import React, { useEffect, useMemo } from 'react';

import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import deDE from 'antd/es/locale/de_DE';
import enUS from 'antd/es/locale/en_US';
import frFR from 'antd/es/locale/fr_FR';
import itIT from 'antd/es/locale/it_IT';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import AuthProvider from 'components/authProvider';
import ContainerProvider from 'components/containerProvider';
import LanguageProvider from 'components/languageProvider';
import Router from 'components/router';
import { useLanguage } from 'hooks';
import { queryClient } from 'services/api';
import authClient from 'services/auth';
import { intiI18n } from 'services/i18n';
import routes from 'services/router';
import { useAuthStore } from 'services/store/auth';
import { appStyles } from 'styles/appStyles';

import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';

const App = () => {
  const language = useLanguage();
  const setToken = useAuthStore(st => st.setToken);
  const antLocale = useMemo(() => {
    switch (language) {
      case 'de':
        return deDE;
      case 'fr':
        return frFR;
      case 'it':
        return itIT;
      default:
        return enUS;
    }
  }, [language]);

  const handleTokens = (tokens: any) => {
    setToken(tokens?.token as string);
  };

  const handleEvent = (event: unknown, error: unknown) => {
    if (window._env_.ENVIRONMENT !== 'prod') {
      switch (event) {
        case 'onReady':
          // eslint-disable-next-line no-console
          console.warn('READY', event, error);
          break;
        case 'onAuthLogout':
          // eslint-disable-next-line no-console
          console.warn('LOGOUT', event, error);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    dayjs.extend(customParseFormat);
    dayjs.extend(advancedFormat);
    dayjs.extend(weekday);
    dayjs.extend(localeData);
    dayjs.extend(weekOfYear);
    dayjs.extend(weekYear);
    dayjs.extend(utc);

    dayjs.locale(i18n._locale);
  }, [language]);

  useEffect(() => {
    intiI18n();
  }, []);

  return (
    <ReactKeycloakProvider
      authClient={authClient}
      initOptions={{
        onLoad: 'login-required'
      }}
      onEvent={handleEvent}
      onTokens={handleTokens}
    >
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <QueryClientProvider client={queryClient}>
            <I18nProvider i18n={i18n as any} forceRenderOnLocaleChange={false}>
              <ConfigProvider
                locale={antLocale}
                theme={{
                  token: {
                    controlHeight: 40,
                    controlHeightLG: 44,
                    controlHeightSM: 32,
                    fontSize: 15,
                    fontFamily: appStyles.fonts.sans.join(','),
                    colorPrimary: appStyles.colors['primary-500'],
                    colorWarning: appStyles.colors.warning,
                    colorWarningBg: appStyles.colors['warning-bg'],
                    colorError: appStyles.colors.error,
                    colorErrorBg: appStyles.colors['error-bg'],
                    colorSuccess: appStyles.colors.success,
                    colorLink: appStyles.colors.primary,
                    colorLinkHover: appStyles.colors.primary,
                    colorLinkActive: appStyles.colors.primary
                  },
                  components: {
                    Menu: {
                      margin: 0,
                      paddingContentVertical: 0,
                      borderRadius: 0,
                      borderRadiusOuter: 0,
                      itemMarginInline: 0
                    },
                    Popover: {
                      sizePopupArrow: 0
                    },
                    Pagination: {
                      controlHeightSM: 24
                    }
                  }
                }}
              >
                <ThemeProvider theme={{ ...appStyles, headerHeight: 64 }}>
                  <LanguageProvider>
                    <ContainerProvider>
                      <AuthProvider>
                        <Router routes={routes} />
                      </AuthProvider>
                    </ContainerProvider>
                  </LanguageProvider>
                </ThemeProvider>
              </ConfigProvider>
            </I18nProvider>
          </QueryClientProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </ReactKeycloakProvider>
  );
};

export default App;
