const o = {
  pagination: {
    page: 1,
    limit: 20
  },
  filters: [],
  sort: {
    field: "updatedAt",
    direction: "DESC"
  }
}, e = {
  pagination: {
    page: 1,
    limit: 20
  },
  filters: [],
  sort: {
    field: "dbox_serverModified",
    direction: "DESC"
  }
}, t = 704, i = 480, s = i / t, n = "DD.MM.YYYY, HH:mm:ss", D = "DD.MM.YYYY", d = "2022-12-17";
export {
  D as DATE_FORMAT,
  n as DATE_TIME_FORMAT,
  i as VIDEO_HEIGHT,
  s as VIDEO_RATIO,
  d as VIDEO_START_DATE,
  t as VIDEO_WIDTH,
  o as defaultJobQuery,
  e as defaultVideosQuery
};
