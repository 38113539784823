import tw, { styled, css } from 'twin.macro';

export const Container = styled.nav(() => [
  css`
    ${tw`h-full relative ml-32`};

    & ul {
      ${tw`flex items-center h-full`};

      & li {
        ${tw`h-full flex items-center`};

        & a {
          ${tw`py-22 mx-12 font-500 opacity-60 border-b-4 border-transparent transition-all`};
          line-height: 1;
          border-bottom-width: 3px;

          & svg {
            ${tw`mr-8`};
          }

          &.active {
            ${tw`opacity-100 border-primary-50`};
          }
        }
      }
    }
  `
]);
