import type { PropsWithChildren } from 'react';
import React, { useEffect } from 'react';

import { i18n } from '@lingui/core';
import { useLocation, useNavigate } from 'react-router';

import { useDefaultLocale, useLanguageFromPath } from 'hooks';
import { languageConfig } from 'services/i18n';
import routes from 'services/router';
import { useLanguageStore } from 'services/store/language';
import { Locale } from 'types';
import { getRoute, getSlugs } from 'utils';

const LanguageProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const language = useLanguageFromPath();
  const setLanguage = useLanguageStore(st => st.setLanguage);
  const defaultLocale = useDefaultLocale();

  useEffect(() => {
    if (!language) {
      // eslint-disable-next-line max-len
      navigate(getRoute(routes.home._path, { query: { language: defaultLocale } }), {
        replace: true
      });

      return;
    }

    let lang = language as Locale;
    if (!languageConfig.locales.includes(lang)) {
      lang = defaultLocale;

      const parts = getSlugs(pathname);

      if (parts.length < 2) {
        navigate(getRoute(routes.home._path, { query: { language: lang } }), { replace: true });

        return;
      }

      parts.splice(0, 1, lang);
      navigate(`/${parts.join('/')}`, { replace: true });

      return;
    }

    i18n.activate(lang);
    setLanguage(lang);
  }, [language, pathname, defaultLocale, navigate, setLanguage]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default LanguageProvider;
