import React from 'react';

import { Video } from '@cheyes/shared';
import { useQueryClient } from '@tanstack/react-query';
import { Switch } from 'antd';

import { useActivateVideoById, useDeactivateVideoById } from 'services/api/rest/videos';

interface ToogleActiveActionProps {
  data: Video;
}

const ToogleActiveAction: React.FC<ToogleActiveActionProps> = ({ data }) => {
  const queryClient = useQueryClient();

  const handleUpdate = () => {
    queryClient.refetchQueries(['videos']);
  };

  const deactivateMutation = useDeactivateVideoById({
    onSuccess: () => {
      handleUpdate();
    }
  });

  const activateMutation = useActivateVideoById({
    onSuccess: () => {
      handleUpdate();
    }
  });

  const handleToggleActive = () => {
    if (data.active) {
      deactivateMutation.mutate(data.id);

      return;
    }

    activateMutation.mutate(data.id);
  };

  return (
    <Switch
      className="toggle-active"
      disabled={deactivateMutation.isLoading || activateMutation.isLoading}
      size="small"
      checked={data.active}
      onChange={handleToggleActive}
    />
  );
};

export default ToogleActiveAction;
