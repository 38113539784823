import React, { PropsWithChildren } from 'react';

import { useKeycloak } from '@react-keycloak/web';

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { keycloak } = useKeycloak();

  if (!keycloak.authenticated) return null;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default AuthProvider;
