import { useCallback } from 'react';

import { useNavigate } from 'react-router';

import type { UseGotoParams } from 'types';
import { getRoute } from 'utils';

import { useLanguage } from './useLanguage';

type UseGotoHandler = (route: string, options?: UseGotoParams) => void;

export const useGoto = (): UseGotoHandler => {
  const navigate = useNavigate();
  const language = useLanguage();

  const handleGotoRoute: UseGotoHandler = useCallback(
    (route, options) => {
      navigate(
        getRoute(route, {
          query: options?.query ? { ...options.query, language } : { language },
          search: options?.search
        }),
        { replace: options?.replace }
      );
    },
    [language, navigate]
  );

  return handleGotoRoute;
};

// exported module federation
export default useGoto;
