import type { CSSProperties } from 'react';
import React from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import type { SpinProps } from 'antd';
import { Spin } from 'antd';
import clsx from 'clsx';
import { omit } from 'lodash';

import * as S from './LoaderSc';

export interface LoaderCustom {
  boxHeight?: number | string;
  boxWidth?: number | string;
  boxClassName?: string;
  boxStyle?: CSSProperties;
  flex?: boolean;
  size?: number;
}

export type LoaderProps = Omit<SpinProps, 'size'> & LoaderCustom;

const Loader: React.FC<LoaderProps> = props => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { boxHeight, boxWidth, boxClassName, boxStyle, size, flex } = props;
  const antProps = omit(props, ['boxHeight', 'boxClassName', 'boxWidth', 'flex', 'size']);
  const antIcon = <LoadingOutlined spin />;

  return (
    <S.StyledLoader
      style={boxStyle}
      className={clsx([
        'loader',
        `loader-${size || 'default'}`,
        'loader-box',
        flex && 'flex',
        boxClassName
      ])}
      height={boxHeight}
      width={boxWidth}
      size={size}
      as={flex ? 'div' : 'span'}
    >
      <Spin {...antProps} indicator={antIcon} />
    </S.StyledLoader>
  );
};

export default Loader;
