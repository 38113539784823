import React from 'react';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Trans } from '@lingui/macro';
import { useKeycloak } from '@react-keycloak/web';
import clsx from 'clsx';
import { useLocation } from 'react-router';

import HeaderButton from 'components/headerButton';
import MainNavigation from 'components/mainNavigation/MainNavigation';
import routes from 'services/router/routes';
import { getRoute, getSlugs } from 'utils';

import * as S from './HeaderSc';

const Header: React.FC = () => {
  const { keycloak } = useKeycloak();
  const { pathname } = useLocation();
  const base = getSlugs(pathname)?.[1];
  const language = getSlugs(pathname)?.[0];

  const handleLogout = () => {
    keycloak.logout();
  };

  return (
    <S.Container>
      <S.Left>
        <S.Logo
          className={clsx([base === 'home' && 'active'])}
          to={getRoute(routes.home._path, { query: { language } })}
        >
          <h2>Chameleon Eyes Videos</h2>
        </S.Logo>
        <MainNavigation />
      </S.Left>

      <S.Right>
        <HeaderButton icon={solid('sign-out')} onClick={handleLogout}>
          <Trans>Sign out</Trans>
        </HeaderButton>
      </S.Right>
    </S.Container>
  );
};

export default Header;
