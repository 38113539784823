import { getRem } from '@arpia/utils';
import tw, { styled, css } from 'twin.macro';

export const ErrorInfo = styled.div(() => [
  css`
    ${tw`mx-12 md:mx-40`};

    & .info {
      ${tw`mt-18 p-12 bg-error-bg border border-error rounded-4`};

      & h2 {
        ${tw`text-16 font-600 text-primary`};

        & svg {
          ${tw`mr-12`};
        }
      }

      & p {
        ${tw`text-13 text-gray-600 m-0 uppercase mt-0 mb-4`};
      }

      & pre {
        ${tw`bg-white p-8 text-14 rounded-4`};

        white-space: pre-line;
        line-break: anywhere;
      }
    }

    & .buttons {
      ${tw`mt-24 flex md:flex-row flex-col`};

      & .ant-btn {
        ${tw`md:mr-12 mb-12 md:mb-0 w-full  border-primary text-primary hover:text-white hover:bg-primary`};
        min-width: ${getRem(120)};

        & svg {
          ${tw`mr-8`};
        }

        &.report {
          ${tw`text-white ml-auto mr-0`};
        }
      }
    }
  `
]);
