import React from 'react';

import { DATE_FORMAT, DATE_TIME_FORMAT } from '@cheyes/shared';
import { Progress } from 'antd';
import dayjs from 'dayjs';

import { useJobId, useRunningJob } from 'hooks';
import { Job as JobType } from 'services/api/rest/jobs';

import AbortJobAction from './AbortJobAction';
import DeleteJobAction from './DeleteJobAction';
import * as S from './JobSc';

interface JobProps {
  data: JobType;
  index: number;
}

const JobItem: React.FC<JobProps> = ({ data }) => {
  const { jobId, setJobId } = useJobId();
  const { jobData, isRunning, percent } = useRunningJob(data);

  return (
    <S.ListItem
      role="button"
      onClick={() => setJobId(jobData.id)}
      isActive={jobId === jobData.id}
      isRunnig={isRunning}
    >
      <div>
        <div>
          <p>Job {jobData.id}</p>
          <p>Import: {dayjs(jobData.dateToFetch).local().format(DATE_FORMAT)}</p>
        </div>
        <div>
          <p>
            {jobData.transformTotal} /{' '}
            {jobData.transformFailed + jobData.transformSuccessful + jobData.transformOther}
          </p>
          <S.Status>
            <span className="status success">{jobData.transformSuccessful}</span> /{' '}
            <span className="status error">{jobData.transformFailed}</span> /{' '}
            <span className="status other">{jobData.transformOther}</span>
          </S.Status>
        </div>
        <div>
          <p>{jobData.status}</p>
          <p>{dayjs(jobData.createdAt).local().format(DATE_TIME_FORMAT)}</p>
        </div>
        <div className="actions">
          {isRunning && <Progress percent={percent} type="circle" size={36} />}
          <AbortJobAction job={data} mode="list" />
          <DeleteJobAction job={data} mode="list" />
        </div>
      </div>
    </S.ListItem>
  );
};

export default JobItem;
