import { ApiSearchQueryParams } from '@cheyes/shared';
import { useMutation, useQuery } from '@tanstack/react-query';

import { UseMutation, UseQuery, UseQueryWithParams } from 'services/api/types';

import {
  api_abortRunningJob,
  api_deleteJobById,
  api_getJobById,
  api_getJobs,
  api_getRunningJobs
} from './jobs.queries';
import { AbortJobResponse, Job, JobListResponse } from './jobs.types';

/**
 * QUERIES
 */
export const useJobs: UseQueryWithParams<ApiSearchQueryParams, JobListResponse> = (
  queryParams,
  options
) =>
  useQuery(['jobs', queryParams], ({ signal }) => api_getJobs(queryParams, signal), options as any);

export const useJobById: UseQueryWithParams<number, Job> = (id, options) =>
  useQuery(['jobs', 'byId', id], ({ signal }) => api_getJobById(id, signal), options as any);

export const useRunningJobs: UseQuery<Job[]> = options =>
  useQuery(['jobs', 'running'], ({ signal }) => api_getRunningJobs(signal), options as any);

/**
 * MUTATIONS
 */
export const useAbortRunningJob: UseMutation<number, AbortJobResponse> = options =>
  useMutation(params => api_abortRunningJob(params), options);

export const useDeleteJobById: UseMutation<number, void> = options =>
  useMutation(params => api_deleteJobById(params), options);
