import React from 'react';

import { Navigate } from 'react-router';

import Imports from 'components/imports';
import PageWrapper from 'components/pageWrapper';
import VideosTable from 'components/videosTable/VideosTable';

export const pathParams = {
  language: ':language',
  id: ':id'
};

const basePath = `${pathParams.language}`;

const getRoutes = () => ({
  home: {
    _segment: 'home',
    _path: `/${basePath}/home`,
    _element: <Navigate to="/en/videos/" replace />
  },

  videos: {
    _segment: 'videos',
    _path: `/${basePath}/videos`,
    _element: <PageWrapper component={<VideosTable />} />
  },

  import: {
    _segment: 'import',
    _path: `/${basePath}/import`,
    _element: <PageWrapper component={<Imports />} />
  }
});

const routes = getRoutes();

export type RouteConfig = ReturnType<typeof getRoutes>;

export default routes as RouteConfig;
