import { ApiSearchQueryParams } from '@cheyes/shared';
import { stringify } from 'qs';

import { apiClient } from 'services/api/clients';

import { AbortJobResponse, Job, JobListResponse } from './jobs.types';

const BASE_PATH = 'jobs';

/**
 * GET /api/jobs
 */
export const api_getJobs = async (
  queryParams: ApiSearchQueryParams,
  signal?: AbortSignal
): Promise<JobListResponse> => {
  const query = stringify(queryParams, { encodeValuesOnly: true });

  const { data } = await apiClient.get(`/${BASE_PATH}`, { signal, params: { query } });

  return data;
};

/**
 * GET /api/jobs/{id}
 */
export const api_getJobById = async (id: number, signal?: AbortSignal): Promise<Job> => {
  const { data } = await apiClient.get(`/${BASE_PATH}/${id}`, { signal });

  return data;
};

/**
 * GET /api/jobs/running
 */
export const api_getRunningJobs = async (signal?: AbortSignal): Promise<Job> => {
  const { data } = await apiClient.get(`/${BASE_PATH}/running`, { signal });

  return data;
};

/**
 * PATCH /api/jobs/terminate/{id}
 */
export const api_abortRunningJob = async (id: number): Promise<AbortJobResponse> => {
  const { data } = await apiClient.patch(`/${BASE_PATH}/terminate/${id}`);

  return data;
};

/**
 * DELETE /api/jobs/{id}
 */
export const api_deleteJobById = async (id: number): Promise<void> => {
  const { data } = await apiClient.delete(`/${BASE_PATH}/${id}`);

  return data;
};
