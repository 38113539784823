import React from 'react';

import Loader from 'components/loader';

import * as S from './PageLoaderSc';

interface PageLoaderProps {
  children?: React.ReactNode;
}

const PageLoader: React.FC<PageLoaderProps> = () => (
  <S.StyledPageLoader>
    <Loader flex boxHeight="100%" size={48} />
  </S.StyledPageLoader>
);

export default PageLoader;
