import React from 'react';

import { ErrorBoundary } from 'react-error-boundary';

import PageLoader from 'components/pageLoader';

// import { useIsAuthenticated } from 'hooks';

import PageContentErrorFallback from './PageContentErrorFallback';

interface PageWrapperProps {
  component: React.ReactNode;
}

const PageWrapper: React.FC<PageWrapperProps> = ({ component }) => (
  <ErrorBoundary
    FallbackComponent={PageContentErrorFallback}
    onReset={() => {
      window.location.reload();
    }}
  >
    <React.Suspense fallback={<PageLoader />}>{component}</React.Suspense>
  </ErrorBoundary>
);

export default PageWrapper;
