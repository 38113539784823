import tw, { styled, css } from 'twin.macro';

export const Container = styled.div(() => [
  css`
    ${tw`overflow-hidden relative flex flex-col`};
    height: calc(100vh - 64px);
  `
]);

export const Header = styled.div(() => [
  css`
    ${tw`bg-gray-200 flex items-center justify-between p-12`};

    & > div.left {
      & .ant-btn {
        ${tw`ml-12 h-38 inline-flex items-center justify-center`}
        transform: translateY(1px);

        &:not(:disabled) {
          ${tw`bg-success hover:bg-success-light hover:border-success-dark`}
        }

        & svg {
          ${tw`mr-8`}
        }
      }
    }

    & > div.right {
      & {
        ${tw`flex items-center`}

        & p {
          ${tw`flex ml-12`}

          span:last-of-type {
            ${tw`flex font-600 inline-block ml-4`}
          }
        }

        & .ant-btn {
          ${tw`ml-12`}
        }
      }
    }
  `
]);

export const Main = styled.div(() => [
  css`
    ${tw`flex-grow h-full relative flex`};

    & > div.left {
      ${tw`relative`};
      width: 55%;
    }

    & > div.right {
      ${tw`relative flex-grow ml-24`};
    }
  `
]);

export const List = styled.div(() => [
  css`
    ${tw`absolute h-full left-0 w-full overflow-x-hidden overflow-y-scroll pt-12`};
  `
]);

export const JobActive = styled.div(() => [
  css`
    ${tw`bg-primary-50 p-12 rounded-6`};

    & > svg {
      ${tw`mr-8`}
    }
  `
]);
