import { VIDEO_RATIO } from '@cheyes/shared';
import tw, { styled, css } from 'twin.macro';

export const Container = styled.div(() => [
  css`
    ${tw`relative flex-grow mt-8`};

    /* & .ant-btn {
      svg {
        ${tw`mr-0`};
      }
    } */
  `
]);

export const VideoWrapper = styled.div(() => [
  css`
    ${tw`w-full`};
    padding-top: ${VIDEO_RATIO * 100}%;

    & video {
      ${tw`absolute left-0 top-0`};
    }
  `
]);

export const Controls = styled.div(() => [
  css`
    ${tw`flex justify-center p-3 relative`};

    & .ant-btn {
      ${tw`text-white opacity-40 hover:opacity-100 border-none `};

      svg {
        ${tw`mr-0 text-white`};
      }

      &:disabled {
        ${tw`opacity-10`};
      }

      &.active {
        ${tw`opacity-80`};
      }
    }

    & .ant-switch {
      ${tw`absolute left-0 top-16 bg-gray-700 hover:bg-gray-600`}

      & .ant-switch-handle:before {
        ${tw`bg-gray-500`}
      }

      &.ant-switch-checked {
        ${tw`bg-gray-700 hover:bg-gray-300`}

        & .ant-switch-handle:before {
          ${tw`bg-gray-500`}
        }
      }
    }
  `
]);

export const Time = styled.div(() => [
  css`
    ${tw`p-8 bg-primary-900 flex justify-center`};

    & .ant-btn {
      ${tw`text-white opacity-50 hover:opacity-100 border-none `};

      svg {
        ${tw`mr-0 text-white`};
      }
    }
  `
]);

export const Info = styled.div(() => [
  css`
    ${tw`text-white  flex justify-between pt-8 text-13`};

    & p {
      ${tw`opacity-40`};

      & svg {
        ${tw`inline-block mr-8`};
      }
    }

    & a:hover p {
      ${tw`opacity-80 transition-all`};
    }
  `
]);
